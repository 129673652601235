import Banner from "../../components/campaign/banner";
import Bedrooms from "../../components/campaign/bedrooms";
import ControlledCarousel from "../../components/campaign/carousel";
import EventMedia from "../../components/campaign/eventMedia";
import Features from "../../components/campaign/features";
import Header from "../../components/campaign/header";
import ProgressBar from "../../components/campaign/progressBar";
import WhatIsIncluded from "../../components/campaign/whatIsIncluded";
import Footer from "../../components/footer";
import Table from "../../components/table";

const Campaign = () => {
  return (
    <>
      <ProgressBar />
      <Header />
      <Banner />
      <Features />
      <EventMedia />
      <WhatIsIncluded />
      <ControlledCarousel />
      <Bedrooms />
      <Table isCampaign={true} />
      <Footer />
    </>
  );
};

export default Campaign;
