import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const FormSelect = ({ control, array, nameSelect, label }) => {
  const tshirtSizesFather = ["P", "M", "G", "GG"];
  const tshirtSizesMother = ["P", "M", "G", "GG"];
  const ageRegistered = ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14"];

  const sizeTshirtRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const sizeShortRegistered = [
    "6",
    "8",
    "10",
    "12",
    "PP(adulto)",
    "P(adulto)",
    "M(adulto)",
    "G(adulto)",
  ];

  const boots = [
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
  ];

  const agency = [
    "Caioba",
    "Ana Luiza Lima Consultoria em Viagens",
    "7 Mares",
    "BORALA Turismo",
    "Bocatur",
    "Camilla Mattar Viagens",
    "Carla Vieira Tours",
    "Carol Cordeiro Viagens",
    "Droptur",
    "Escape Tour",
    "Eventho Turismo",
    "Gentile Travel",
    "GF Turismo",
    "Golf Travel",
    "HOSTWAY TRAVEL",
    "Laura Malz Viagens",
    "LRB Viagens",
    "Love Trips",
    "MMA VIAGENS",
    "My Tour Turismo",
    "Next Trip Viagens",
    "Novitá Turismo",
    "Patrícia Saraiva Travel",
    "PHD Travel",
    "Set Travel",
    "SN3 Viagens",
    "Viagens e Descobertas",
    "World Destiny",
  ];

  const gender = ["Masculino", "Feminino"];

  const eventDate = ["04 à 06", "11 à 13"];

  const arrayMap = {
    tshirtSizesFather: tshirtSizesFather,
    tshirtSizesMother: tshirtSizesMother,
    ageRegistered: ageRegistered,
    sizeTshirtRegistered: sizeTshirtRegistered,
    sizeShortRegistered: sizeShortRegistered,
    boots: boots,
    agency: agency,
    gender: gender,
    eventDate: eventDate,
  };

  return (
    <Controller
      control={control}
      name={nameSelect}
      rules={{ required: true }}
      defaultValue=""
      render={({ field: { value, onChange } }) => (
        <FormControl fullWidth sx={{ mb: 2 }} required>
          <InputLabel>{label}</InputLabel>
          <Select
            name={nameSelect}
            value={value || ""}
            onChange={onChange}
            label={label}
            MenuProps={{
              style: { zIndex: 99999 },
            }}
          >
            {arrayMap[array]?.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );
};

export default FormSelect;
